import { axiosInstance as axios } from '../../boot/axios'
import { axiosInstance } from '../../boot/axios-assembly'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    MESManufacturerOrderList({ commit, rootGetters }, request) {
      return axios
        .post('ManufacturerOrderList', {
          ...request
        })
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESManufacturerOrdersSummaryDetail({ commit, rootGetters }, request) {
      return axios
        .post('ManufacturerOrdersSummaryDetail', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('獲取訂單金額失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    BoardUploadImage({ commit, rootGetters }, request) {
      const formData = new FormData()
      formData.append('BoardImage', request.BoardImage)
      formData.append('BoardGuid', request.BoardGuid)
      return axios
        .post('BoardUploadImage', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //製造商五金上传图片POST
    MetalUploadImage({ commit, rootGetters }, request) {
      const formData = new FormData()
      formData.append('MetalGuid', request.MetalGuid)
      formData.append('MetalImage', request.MetalImage)
      return axios
        .post('MetalUploadImage', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESCheckMesOrders({ commit, rootGetters }, request) {
      return axios
        .post('CheckMesOrders', {
          OrderIds: request.OrderIds
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('訂單 Id 不存在')
          } else {
            return Promise.reject('檢查失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetEOfficeOrderMetals({ commit, rootGetters }, request) {
      return axios
        .post('GetEOfficeOrderMetals', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESManufacturerOrderRead({ commit, rootGetters }, request) {
      return axios
        .post('ManufacturerOrderRead', {
          OrderID: request.OrderID
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    NewManufacturerOrderRead({ commit, rootGetters }, request) {
      return axios
        .post('NewManufacturerOrderRead', {
          OrderID: request.OrderID
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('不是該廠商的訂單')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESOrderPrice({ rootGetters }, request) {
      let formData = new FormData()

      formData.append('OrderID', request.OrderID)

      request.DemandXmls.forEach((demand, index) => {
        formData.append(`DemandXmls[${index}].DemandID`, demand.DemandID)
        formData.append(`DemandXmls[${index}].ManufacturerProcessFile`, demand.ManufacturerProcessFile)
        formData.append(`DemandXmls[${index}].PackageRemark`, demand.PackageRemark)
        formData.append(`DemandXmls[${index}].IsFileExist`, demand.IsFileExist)
      })

      return axios
        .post('OrderPrice', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -5) {
            return Promise.reject({
              message: '訂單內有排除的封邊類型',
              data: {
                edgeBandingExcludeBoards: response.data.Values.EdgeBandingExcludeBoards
              }
            })
          } else if (response.data.Values.ReqInt === -4) {
            return Promise.reject({
              message: '缺少的原料板',
              data: {
                lackRawBoards: response.data.Values.OrderPriceLackRawBoards
              }
            })
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject({
              message: '缺少物料',
              data: {
                lackBoards: response.data.Values.LackBoards,
                lackMetals: response.data.Values.LackMetals,
                lackBandingStriples: response.data.Values.LackBandingStriples
              }
            })
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('XML格式錯誤')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },

    NewOrderPrice({ rootGetters }, request) {
      let formData = new FormData()

      formData.append('OrderID', request.OrderID)

      request.DemandXmls.forEach((demand, index) => {
        formData.append(`DemandXmls[${index}].DemandID`, demand.DemandID)
        formData.append(`DemandXmls[${index}].ManufacturerProcessFile`, demand.ManufacturerProcessFile)
        formData.append(`DemandXmls[${index}].PackageRemark`, demand.PackageRemark)
        formData.append(`DemandXmls[${index}].IsFileExist`, demand.IsFileExist)
      })

      return axios
        .post('NewOrderPrice', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -5) {
            return Promise.reject({
              message: '訂單內有排除的封邊類型',
              data: {
                edgeBandingExcludeBoards: response.data.Values.EdgeBandingExcludeBoards
              }
            })
          } else if (response.data.Values.ReqInt === -4) {
            return Promise.reject({
              message: '缺少的原料板',
              data: {
                lackRawBoards: response.data.Values.OrderPriceLackRawBoards
              }
            })
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject({
              message: '缺少物料',
              data: {
                lackBoards: response.data.Values.LackBoards,
                lackMetals: response.data.Values.LackMetals,
                lackBandingStriples: response.data.Values.LackBandingStriples
              }
            })
          } else if (response.data.Values.ReqInt === -7) {
            return Promise.reject({
              message: '缺少原料板Sn',
              data: {
                lackRawBoardsSn: response.data.Values.OrderPriceLackRawBoardSns
              }
            })
          } else if (response.data.Values.ReqInt === -6) {
            return Promise.reject({
              message: '缺少小板編號',
              data: {
                OrderPriceLackSmallPlates: response.data.Values.OrderPriceLackSmallPlates
              }
            })
          } else if (response.data.Values.ReqInt === -8) {
            return Promise.reject({
              message: '缺少五金料號',
              data: {
                OrderPriceLackMetalProductNumbers: response.data.Values.OrderPriceLackMetalProductNumbers
              }
            })
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('XML格式錯誤')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSaveOrderPrice({ rootGetters }, request) {
      return axios
        .post('SaveOrderPrice', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DeleteOrderCabinetsMetals({ rootGetters }, request) {
      return axios
        .get(`DeleteOrderCabinetsMetals?orderId=${request.orderId}`)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    NewSaveOrderPrice({ rootGetters }, request) {
      return axios
        .post('NewSaveOrderPrice', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESManufacturerOffer_Agree({ commit, rootGetters }, request) {
      return axios
        .post('ManufacturerOffer', {
          OrderID: request.OrderID,
          ConfirmOrder: true,
          OrderDelivery: request.OrderDelivery,
          OrderAssembly: request.OrderAssembly,
          OfferDelivery: request.OfferDelivery,
          OfferAssembly: request.OfferAssembly,
          OrderAddress: request.OrderAddress,
          ExpenseRemark: request.ExpenseRemark,
          OfferDays: request.OfferDays,
          OfferRemark: request.OfferRemark
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('回覆報價失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    NewManufacturerOffer({ commit, rootGetters }, request) {
      return axios
        .post('NewManufacturerOffer', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('回覆報價失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESManufacturerOffer_DisAgree({ commit, rootGetters }, request) {
      return axios
        .post('ManufacturerOffer', {
          OrderID: request.OrderID,
          ConfirmOrder: false
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESManufacturerOrderStart({ commit, rootGetters }, request) {
      return axios
        .post('ManufacturerOrderStart', {
          OrderID: request.OrderID
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESManufacturerOrderFinish({ commit, rootGetters }, request) {
      return axios
        .post('ManufacturerOrderFinish', {
          OrderID: request.OrderID
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('訂單不存在')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('訂單狀態不是生產中')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('寄送Email失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESManufacturerOrderDelete({ commit, rootGetters }, request) {
      return axios
        .post('ManufacturerOrderDelete', {
          OrderID: request.OrderId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetOrderClientCompare({ commit, rootGetters }, request) {
      return axios
        .post('OrderClient/GetOrderClientCompare', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESManufacturerOrderDetailModify({ commit, rootGetters }, request) {
      return axios
        .post('ManufacturerOrderDetailModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('訂單不存在')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('訂單類型錯誤')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESManufacturerOrderDeliveryDateModify({ commit, rootGetters }, request) {
      return axios
        .post('ManufacturerOrderDeliveryDateModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('訂單不存在')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('訂單狀態錯誤')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('交貨日期錯誤 (交貨日期必須大於等於今天)')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    NewManufacturerOrderReject({ commit, rootGetters }, request) {
      return axios
        .post('NewManufacturerOrderReject', {
          OrderID: request.OrderID
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('訂單不存在')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('訂單狀態錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESManufacturerOrderReject({ commit, rootGetters }, request) {
      return axios
        .post('ManufacturerOrderReject', {
          OrderID: request.OrderID
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('訂單不存在')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('訂單狀態錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESManufacturerOrderReview({ commit, rootGetters }, request) {
      return axios
        .post('ManufacturerOrderReview', {
          OrderID: request.OrderID
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('訂單不存在')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('錯誤訂單狀態')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('已審核過')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESBoardList({ commit, rootGetters }, request) {
      return axios
        .post('BoardList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESBoardRead({ commit, rootGetters }, request) {
      return axios
        .post('BoardRead', {
          BoardGuid: request.BoardGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    OrderPriceUploadFile({ commit, rootGetters }, request) {
      const formData = new FormData()
      formData.append('OrderId', request.OrderId)
      formData.append('OrderPriceFile', request.OrderPriceFile)
      return axios
        .post('OrderPriceUploadFile', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    OrderPriceDeleteFile({ commit, rootGetters }, request) {
      const formData = new FormData()
      formData.append('OrderId', request.OrderId) 
      formData.append('DeleteFileName', request.DeleteFileName) 
      return axios
        .post('OrderPriceDeleteFile', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESOrderPriceFileDownLoad({ commit, rootGetters }, request) {
      return axios
        .post(
          'OrderPriceFileDownLoad',
          {
            ...request
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    Upload({ commit, rootGetters }, request) {
      const formData = new FormData()
      formData.append('File', request.File)
      return axios
        .post(process.env.ASSEMBLY_FILE_URL + '/api/file/UploadFileResponseUrlTo', formData)
        .then((response) => {
          if (response.data.code === 200) {
            return Promise.resolve(response.data)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    UploadImageFile({ commit, rootGetters }, request) {
      const formData = new FormData()
      formData.append('File', request.File)
      return axios
        .post(process.env.ASSEMBLY_FILE_URL + '/api/file/UploadImageFile', formData)
        .then((response) => {
          if (response.data.code === 200) {
            return Promise.resolve(response.data)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    UploadAbnormalImageFile({ commit, rootGetters }, request) {
      const formData = new FormData()
      formData.append('File', request.File)
      return axios
        .post(process.env.ASSEMBLY_FILE_URL + '/api/file/UploadAbnormalImageFile', formData)
        .then((response) => {
          if (response.data.code === 200) {
            return Promise.resolve(response.data)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESBoardAdd({ commit, rootGetters }, request) {
      const formData = new FormData()

      formData.append('ProductNumber', request.ProductNumber)
      formData.append('BasicMaterial', request.BasicMaterial)
      formData.append('Material', request.Material)
      formData.append('Thickness', request.Thickness)
      formData.append('UnitPrice', request.UnitPrice)
      formData.append('MinimumSquareFoot', request.MinimumSquareFoot)
      formData.append('HasImg', request.HasImg)
      formData.append('IsQuotable', request.IsQuotable)
      formData.append('BoardImage', request.BoardImage)
      formData.append('Note', request.Note)

      return axios
        .post('BoardAdd', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('板材重複')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESBoardModify({ commit, rootGetters }, request) {
      const formData = new FormData()

      formData.append('ProductNumber', request.ProductNumber)
      formData.append('BoardGuid', request.BoardGuid)
      formData.append('BasicMaterial', request.BasicMaterial)
      formData.append('Material', request.Material)
      formData.append('Thickness', request.Thickness)
      formData.append('UnitPrice', request.UnitPrice)
      formData.append('MinimumSquareFoot', request.MinimumSquareFoot)
      formData.append('HasImg', request.HasImg)
      formData.append('IsQuotable', request.IsQuotable)
      formData.append('BoardImage', request.BoardImage)
      formData.append('Note', request.Note)

      return axios
        .post('BoardModify', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('板材重複')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESBoardDelete({ commit, rootGetters }, request) {
      return axios
        .post('BoardDelete', {
          BoardGuids: request.BoardGuids
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESBoardImport({ commit, rootGetters }, request) {
      let formData = new FormData()

      formData.append('ExcelFile', request.ExcelFile)
      formData.append('IsOverride', request.IsOverride)

      return axios
        .post('BoardImport', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('板材重複')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('檔案內有重複項目')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESBoardExport({ commit, rootGetters }, request) {
      return axios
        .post(
          'BoardExport',
          {
            ...request
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDoorList({ commit, rootGetters }, request) {
      return axios
        .post('DoorList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDoorRead({ commit, rootGetters }, request) {
      return axios
        .post('DoorRead', {
          DoorGuid: request.DoorGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDoorAdd({ commit, rootGetters }, request) {
      return axios
        .post('DoorAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('門板重複')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDoorModify({ commit, rootGetters }, request) {
      return axios
        .post('DoorModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('門板重複')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDoorDelete({ commit, rootGetters }, request) {
      return axios
        .post('DoorDelete', {
          DoorGuids: request.DoorGuids
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDoorImport({ commit, rootGetters }, request) {
      let formData = new FormData()

      formData.append('ExcelFile', request.ExcelFile)
      formData.append('IsOverride', request.IsOverride)

      return axios
        .post('DoorImport', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return response.data.Values
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('門板重複')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('檔案內有重複項目')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDoorExport({ commit, rootGetters }, request) {
      return axios
        .post(
          'DoorExport',
          {
            ...request
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorList({ commit, rootGetters }, request) {
      return axios
        .post('SlideDoorList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorRead({ commit, rootGetters }, request) {
      return axios
        .post('SlideDoorRead', {
          SlideDoorId: request.SlideDoorId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorAdd({ commit, rootGetters }, request) {
      return axios
        .post('SlideDoorAdd', {
          ProductNumber: request.ProductNumber,
          PartNumber: request.PartNumber,
          BasicMaterial: request.BasicMaterial,
          Material: request.Material,
          Thickness: request.Thickness,
          UnitPrice: request.UnitPrice,
          MinimumSquareFoot: request.MinimumSquareFoot,
          Note: request.Note
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('推拉門重複')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorModify({ commit, rootGetters }, request) {
      return axios
        .post('SlideDoorModify', {
          SlideDoorId: request.SlideDoorId,
          PartNumber: request.PartNumber,
          ProductNumber: request.ProductNumber,
          BasicMaterial: request.BasicMaterial,
          Material: request.Material,
          Thickness: request.Thickness,
          UnitPrice: request.UnitPrice,
          MinimumSquareFoot: request.MinimumSquareFoot,
          Note: request.Note
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('推拉門重複')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorDelete({ commit, rootGetters }, request) {
      return axios
        .post('SlideDoorDelete', {
          SlideDoorIds: request.SlideDoorIds
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorImport({ commit, rootGetters }, request) {
      let formData = new FormData()

      formData.append('ExcelFile', request.ExcelFile)
      formData.append('IsOverride', request.IsOverride)

      return axios
        .post('SlideDoorImport', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return response.data.Values
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('推拉門重複')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('檔案內有重複項目')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorExport({ commit, rootGetters }, request) {
      return axios
        .post(
          'SlideDoorExport',
          {
            ...request
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDoorCoreList({ commit, rootGetters }, request) {
      return axios
        .post('DoorCoreList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDoorCoreRead({ commit, rootGetters }, request) {
      return axios
        .post('DoorCoreRead', {
          DoorCoreId: request.DoorCoreId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDoorCoreAdd({ commit, rootGetters }, request) {
      return axios
        .post('DoorCoreAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('門芯重複')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDoorCoreModify({ commit, rootGetters }, request) {
      return axios
        .post('DoorCoreModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('門芯重複')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDoorCoreDelete({ commit, rootGetters }, request) {
      return axios
        .post('DoorCoreDelete', {
          DoorCoreIds: request.DoorCoreIds
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDoorCoreImport({ commit, rootGetters }, request) {
      let formData = new FormData()

      formData.append('ExcelFile', request.ExcelFile)
      formData.append('IsOverride', request.IsOverride)

      return axios
        .post('DoorCoreImport', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return response.data.Values
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('門芯重複')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('檔案內有重複項目')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDoorCoreExport({ commit, rootGetters }, request) {
      return axios
        .post(
          'DoorCoreExport',
          {
            ...request
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorStripList({ commit, rootGetters }, request) {
      return axios
        .post('SlideDoorStripList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorStripRead({ commit, rootGetters }, request) {
      return axios
        .post('SlideDoorStripRead', {
          SlideDoorStripId: request.SlideDoorStripId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorStripAdd({ commit, rootGetters }, request) {
      return axios
        .post('SlideDoorStripAdd', {
          ProductNumber: request.ProductNumber,
          BasicMaterial: request.BasicMaterial,
          Material: request.Material,
          UnitPrice: request.UnitPrice,
          MinimumLength: request.MinimumLength,
          Note: request.Note
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('推拉門區隔條重複')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorStripModify({ commit, rootGetters }, request) {
      return axios
        .post('SlideDoorStripModify', {
          SlideDoorStripId: request.SlideDoorStripId,
          ProductNumber: request.ProductNumber,
          BasicMaterial: request.BasicMaterial,
          Material: request.Material,
          UnitPrice: request.UnitPrice,
          MinimumLength: request.MinimumLength,
          Note: request.Note
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('推拉門區隔條重複')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorStripDelete({ commit, rootGetters }, request) {
      return axios
        .post('SlideDoorStripDelete', {
          SlideDoorStripIds: request.SlideDoorStripIds
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorStripImport({ commit, rootGetters }, request) {
      let formData = new FormData()

      formData.append('ExcelFile', request.ExcelFile)
      formData.append('IsOverride', request.IsOverride)

      return axios
        .post('SlideDoorStripImport', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return response.data.Values
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('推拉門區隔條重複')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('檔案內有重複項目')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorStripExport({ commit, rootGetters }, request) {
      return axios
        .post(
          'SlideDoorStripExport',
          {
            ...request
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorRailList({ commit, rootGetters }, request) {
      return axios
        .post('SlideDoorRailList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorRailRead({ commit, rootGetters }, request) {
      return axios
        .post('SlideDoorRailRead', {
          SlideDoorRailId: request.SlideDoorRailId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorRailAdd({ commit, rootGetters }, request) {
      return axios
        .post('SlideDoorRailAdd', {
          ProductNumber: request.ProductNumber,
          BasicMaterial: request.BasicMaterial,
          Material: request.Material,
          UnitPrice: request.UnitPrice,
          MinimumLength: request.MinimumLength,
          Note: request.Note
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('推拉門軌道重複')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorRailModify({ commit, rootGetters }, request) {
      return axios
        .post('SlideDoorRailModify', {
          SlideDoorRailId: request.SlideDoorRailId,
          ProductNumber: request.ProductNumber,
          BasicMaterial: request.BasicMaterial,
          Material: request.Material,
          UnitPrice: request.UnitPrice,
          MinimumLength: request.MinimumLength,
          Note: request.Note
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('推拉門軌道重複')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorRailDelete({ commit, rootGetters }, request) {
      return axios
        .post('SlideDoorRailDelete', {
          SlideDoorRailIds: request.SlideDoorRailIds
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorRailImport({ commit, rootGetters }, request) {
      let formData = new FormData()

      formData.append('ExcelFile', request.ExcelFile)
      formData.append('IsOverride', request.IsOverride)

      return axios
        .post('SlideDoorRailImport', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return response.data.Values
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('推拉門軌道重複')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('檔案內有重複項目')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSlideDoorRailExport({ commit, rootGetters }, request) {
      return axios
        .post(
          'SlideDoorRailExport',
          {
            ...request
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESMetalList({ commit, rootGetters }, request) {
      return axios
        .post('MetalList', request)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESGetMetalList({ commit, rootGetters }, request) {
      return axios
        .post('GetMetalList', request)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESMetalRead({ commit, rootGetters }, request) {
      return axios
        .post('MetalRead', {
          MetalGuid: request.MetalGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESMetalAdd({ commit, rootGetters }, request) {
      return axios
        .post('MetalAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESMetalModify({ commit, rootGetters }, request) {
      return axios
        .post('MetalModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESMetalDelete({ commit, rootGetters }, request) {
      return axios
        .post('MetalDelete', {
          MetalGuids: request.MetalGuids
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESMetalImport({ commit, rootGetters }, request) {
      let formData = new FormData()

      formData.append('MetalType', request.MetalType)
      formData.append('ExcelFile', request.ExcelFile)
      formData.append('IsOverride', request.IsOverride)

      return axios
        .post('MetalImport', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return response.data.Values.Data
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESMetalExport({ commit, rootGetters }, request) {
      return axios
        .post(
          'MetalExport',
          {
            ...request
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDiscountList({ commit, rootGetters }, request) {
      return axios
        .post('DiscountList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDiscountRead({ commit, rootGetters }, request) {
      return axios
        .post('DiscountRead', {
          PartnerID: request.PartnerID
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDiscountModify({ commit, rootGetters }, request) {
      return axios
        .post('DiscountModify', {
          DiscountModifys: request.DiscountModifys
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDownloadQuotation({ commit, rootGetters }, request) {
      return axios
        .post(
          'DownloadQuotation',
          {
            OrderID: request.OrderID
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    NewDownloadQuotation({ commit, rootGetters }, request) {
      return axios
        .post(
          'NewDownloadQuotation',
          {
            OrderID: request.OrderID
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DownloadWorkOrder({ commit, rootGetters }, request) {
      return axios
        .post(
          'DownloadWorkOrder',
          {
            OrderIDs: request.OrderIDs
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DownloadBom({ commit, rootGetters }, request) {
      return axios
        .post(
          'DownloadBom',
          {
            OrderID: request.OrderID,
            BomArea: request.BomArea
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    OrderDemandConfigRead({ commit, rootGetters }, request) {
      return axios
        .post('OrderDemandConfigRead', {
          OrderID: request.OrderID
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    OrderPricePreview({ commit, rootGetters }, request) {
      return axios
        .post('OrderPricePreview', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    NewOrderPricePreview({ commit, rootGetters }, request) {
      return axios
        .post('NewOrderPricePreview', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    NewOrderPricePreviewByCabinetId({ commit, rootGetters }, request) {
      return axios
        .post('NewOrderPricePreviewByCabinetId', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetBandstripleList({ commit, rootGetters }, request) {
      return axios
        .post('GetBandstripleList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    AllMetalList({ commit, rootGetters }, request) {
      return axios
        .post('AllMetalList', {
          MetalType: request.MetalType
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ConnectorSolutionList({ commit, rootGetters }, request) {
      return axios
        .post('ConnectorSolutionList', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ConnectorSolutionRead({ commit, rootGetters }, request) {
      return axios
        .post('ConnectorSolutionRead', {
          ConnectorSolutionGuid: request.ConnectorSolutionGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ConnectorSolutionSet({ commit, rootGetters }, request) {
      return axios
        .post('ConnectorSolutionSet', {
          ConnectorSolutionAdd: request.ConnectorSolutionAdd,
          ConnectorSolutionModify: request.ConnectorSolutionModify,
          ConnectorSolutionDelete: request.ConnectorSolutionDelete
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('五金方案儲存成功')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    WallCabinetSolutionList({ commit, rootGetters }, request) {
      return axios
        .post('WallCabinetSolutionList', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    WallCabinetSolutionRead({ commit, rootGetters }, request) {
      return axios
        .post('WallCabinetSolutionRead', {
          WallCabinetSolutionGuid: request.WallCabinetSolutionGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    WallCabinetSolutionSet({ commit, rootGetters }, request) {
      return axios
        .post('WallCabinetSolutionSet', {
          WallCabinetSolutionAdd: request.WallCabinetSolutionAdd,
          WallCabinetSolutionModify: request.WallCabinetSolutionModify,
          WallCabinetSolutionDelete: request.WallCabinetSolutionDelete,
          WallCabinetSolutionSetDefault: request.WallCabinetSolutionSetDefault
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('吊櫃方案儲存成功')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    BoardConfigList({ commit, rootGetters }, request) {
      return axios
        .post('BoardConfigList', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    BoardConfigRead({ commit, rootGetters }, request) {
      return axios
        .post('BoardConfigRead', {
          BoardConfigGuid: request.BoardConfigGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    BoardConfigSet({ commit, rootGetters }, request) {
      return axios
        .post('BoardConfigSet', {
          BoardConfigAdd: request.BoardConfigAdd,
          BoardConfigModify: request.BoardConfigModify,
          BoardConfigDelete: request.BoardConfigDelete,
          BoardConfigSetDefault: request.BoardConfigSetDefault
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('連接件類型配置儲存成功')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExtendedRailSolutionList({ commit, rootGetters }, request) {
      return axios
        .post('ExtendedRailSolutionList', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExtendedRailSolutionRead({ commit, rootGetters }, request) {
      return axios
        .post('ExtendedRailSolutionRead', {
          ExtendedRailSolutionGuid: request.ExtendedRailSolutionGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExtendedRailSolutionSet({ commit, rootGetters }, request) {
      return axios
        .post('ExtendedRailSolutionSet', {
          ExtendedRailSolutionAdd: request.ExtendedRailSolutionAdd,
          ExtendedRailSolutionModify: request.ExtendedRailSolutionModify,
          ExtendedRailSolutionDelete: request.ExtendedRailSolutionDelete,
          ExtendedRailSolutionSetDefault: request.ExtendedRailSolutionSetDefault
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('三截滑軌方案儲存成功')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    HiddenRailSolutionList({ commit, rootGetters }, request) {
      return axios
        .post('HiddenRailSolutionList', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    HiddenRailSolutionRead({ commit, rootGetters }, request) {
      return axios
        .post('HiddenRailSolutionRead', {
          HiddenRailSolutionGuid: request.HiddenRailSolutionGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    HiddenRailSolutionSet({ commit, rootGetters }, request) {
      return axios
        .post('HiddenRailSolutionSet', {
          HiddenRailSolutionAdd: request.HiddenRailSolutionAdd,
          HiddenRailSolutionModify: request.HiddenRailSolutionModify,
          HiddenRailSolutionDelete: request.HiddenRailSolutionDelete,
          HiddenRailSolutionSetDefault: request.HiddenRailSolutionSetDefault
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('隠藏式滑軌方案儲存成功')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    HingeSolutionList({ commit, rootGetters }, request) {
      return axios
        .post('HingeSolutionList', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ThickHingeSolutionList({ commit, rootGetters }, request) {
      return axios
        .post('ThickHingeSolutionList', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    HingeSolutionRead({ commit, rootGetters }, request) {
      return axios
        .post('HingeSolutionRead', {
          HingeSolutionGuid: request.HingeSolutionGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ThickHingeSolutionRead({ commit, rootGetters }, request) {
      return axios
        .post('ThickHingeSolutionRead', {
          ThickHingeSolutionGuid: request.ThickHingeSolutionGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ThickHingeSolutionSet({ commit, rootGetters }, request) {
      return axios
        .post('ThickHingeSolutionSet', {
          ThickHingeSolutionAdd: request.ThickHingeSolutionAdd,
          ThickHingeSolutionModify: request.ThickHingeSolutionModify,
          ThickHingeSolutionDelete: request.ThickHingeSolutionDelete,
          ThickHingeSolutionSetDefault: request.ThickHingeSolutionSetDefault
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('鉸鏈方案儲存成功')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    HingeSolutionSet({ commit, rootGetters }, request) {
      return axios
        .post('HingeSolutionSet', {
          HingeSolutionAdd: request.HingeSolutionAdd,
          HingeSolutionModify: request.HingeSolutionModify,
          HingeSolutionDelete: request.HingeSolutionDelete,
          HingeSolutionSetDefault: request.HingeSolutionSetDefault
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('鉸鏈方案儲存成功')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FlipSolutionList({ commit, rootGetters }, request) {
      return axios
        .post('FlipSolutionList', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FlipSolutionRead({ commit, rootGetters }, request) {
      return axios
        .post('FlipSolutionRead', {
          FlipSolutionGuid: request.FlipSolutionGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FlipSolutionSet({ commit, rootGetters }, request) {
      return axios
        .post('FlipSolutionSet', {
          FlipSolutionAdd: request.FlipSolutionAdd,
          FlipSolutionModify: request.FlipSolutionModify,
          FlipSolutionDelete: request.FlipSolutionDelete,
          FlipSolutionSetDefault: request.FlipSolutionSetDefault
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('掀門油壓橕桿方案儲存成功')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    AdjustableFootSolutionList({ commit, rootGetters }, request) {
      return axios
        .post('AdjustableFootSolutionList', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    AdjustableFootSolutionRead({ commit, rootGetters }, request) {
      return axios
        .post('AdjustableFootSolutionRead', {
          AdjustableFootSolutionGuid: request.AdjustableFootSolutionGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    AdjustableFootSolutionSet({ commit, rootGetters }, request) {
      return axios
        .post('AdjustableFootSolutionSet', {
          AdjustableFootSolutionAdd: request.AdjustableFootSolutionAdd,
          AdjustableFootSolutionModify: request.AdjustableFootSolutionModify,
          AdjustableFootSolutionDelete: request.AdjustableFootSolutionDelete,
          AdjustableFootSolutionSetDefault: request.AdjustableFootSolutionSetDefault
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('調整腳方案儲存成功')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    EdgeBandingExtraPriceRead({ commit, rootGetters }) {
      return axios
        .post('EdgeBandingExtraPriceRead', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    EdgeBandingExtraPriceSet({ commit, rootGetters }, request) {
      return axios
        .post('EdgeBandingExtraPriceSet', {
          ExtraPrice: request.ExtraPrice,
          EdgeBandingExcludeTypes: request.EdgeBandingExcludeTypes
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('封邊特殊加價設定儲存成功')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    AccurateExtraPriceRead({ commit, rootGetters }) {
      return axios
        .post('AccurateExtraPriceRead', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    AccurateExtraPriceSet({ commit, rootGetters }, request) {
      return axios
        .post('AccurateExtraPriceSet', {
          ExtraPrice: request.ExtraPrice,
          MinimumSquareFoot: request.MinimumSquareFoot,
          Note: request.Note
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('板材特殊加價設定儲存成功')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DrawerPartNumberRead({ commit, rootGetters }) {
      return axios
        .post('DrawerPartNumberRead', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DrawerPartNumberSet({ commit, rootGetters }, request) {
      return axios
        .post('DrawerPartNumberSet', {
          DrawerPartNumbers: request.DrawerPartNumbers,
          HiddenDrawerPartNumbers: request.HiddenDrawerPartNumbers
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('抽屜部件編號設定儲存成功')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('抽屜部件編號重覆')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SpecificExtraPriceList({ commit, rootGetters }, request) {
      return axios
        .post('SpecificExtraPriceList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSpecificExtraPriceRead({ commit, rootGetters }, request) {
      return axios
        .post('SpecificExtraPriceRead', {
          SpecificExtraPriceGuid: request.SpecificExtraPriceGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSpecificExtraPriceAdd({ commit, rootGetters }, request) {
      return axios
        .post('SpecificExtraPriceAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSpecificExtraPriceModify({ commit, rootGetters }, request) {
      return axios
        .post('SpecificExtraPriceModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSpecificExtraPriceDelete({ commit, rootGetters }, request) {
      return axios
        .post('SpecificExtraPriceDelete', {
          SpecificExtraPriceGuids: request.SpecificExtraPriceGuids
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SlideDoorPriceSet({ rootGetters }, request) {
      return axios
        .post('SlideDoorPriceSet', {
          CustomizeSlideDoorPriceRule: request.CustomizeSlideDoorPriceRule,
          SlideDoor: request.SlideDoor,
          DoorCore: request.DoorCore,
          SlideDoorStrip: request.SlideDoorStrip,
          Buffer: request.Buffer,
          Rail: request.Rail
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('推拉門計價規則設定儲存成功')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SlideDoorPriceRead({ rootGetters }) {
      return axios
        .post('SlideDoorPriceRead', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    InclinedHandlePriceSet({ rootGetters }, request) {
      return axios
        .post('InclinedHandlePriceSet', {
          CalcInclinedHandle: request.CalcInclinedHandle
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('斜把手計價變更設定儲存成功')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    InclinedHandlePriceRead({ rootGetters }) {
      return axios
        .post('InclinedHandlePriceRead', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DoorCorePriceSet({ rootGetters }, request) {
      return axios
        .post('DoorCorePriceSet', {
          DoorCore: request.DoorCore
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('門芯計價變更設定儲存成功')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DoorCorePriceRead({ rootGetters }) {
      return axios
        .post('DoorCorePriceRead', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ToWorkOrder({ rootGetters }, request) {
      let formData = new FormData()

      formData.append('WorkOrderName', request.WorkOrderName)
      formData.append('AutoLineFile', request.AutoLineFile)
      formData.append('ManualLineFile', request.ManualLineFile)
      formData.append('EstimatedTime', request.EstimatedTime)
      formData.append('DeptId', request.DeptId)

      request.OrderIds.forEach((orderId) => formData.append('OrderIds', orderId))

      return axios
        .post('ToWorkOrder', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('轉換工單成功')
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('自動線檔案上傳失敗')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('半自動線檔案上傳失敗')
          } else if (response.data.Values.ReqInt === -4) {
            return Promise.reject('自動線Central上傳失敗:' + response.data.Values.ReqError)
          } else if (response.data.Values.ReqInt === -5) {
            return Promise.reject('半自動線Central上傳失敗')
          } else if (response.data.Values.ReqInt === -6) {
            return Promise.reject('自動線附件錯誤')
          } else if (response.data.Values.ReqInt === -7) {
            return Promise.reject('半自動線附件錯誤')
          } else if (response.data.Values.ReqInt === -8) {
            return Promise.reject({
              data: response.data.Values,
              message: '缺少物料'
            })
          } else if (response.data.Values.ReqInt === -9) {
            return Promise.reject('不可包含豎紋板材')
          } else if (response.data.Values.ReqInt === -10) {
            return Promise.reject('半自動線密排失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DownloadModifiedFile({ rootGetters }, request) {
      return axios
        .post(
          'DownloadModifiedFile',
          {
            OrderID: request.OrderID,
            DemandID: request.DemandID
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DownloadManualLineFile({ rootGetters }, request) {
      return axios
        .post(
          'DownloadManualLineFile',
          {
            OrderID: request.OrderID,
            DemandID: request.DemandID
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DownloadAutoLineFile({ rootGetters }, request) {
      return axios
        .post(
          'DownloadAutoLineFile',
          {
            OrderID: request.OrderID,
            DemandID: request.DemandID
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ReadyPackingOrderList({ rootGetters }, request) {
      return axios
        .post('ReadyPackingOrderList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DeptInfoItemList({ rootGetters }, request) {
      return axios
        .post('DeptInfoItemList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DecorationItemAdd({ rootGetters }, request) {
      return axios
        .post('DecorationItemAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          }
          if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('品項已存在')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DecorationItemRead({ rootGetters }, request) {
      return axios
        .post('DecorationItemRead', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          }
          if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DecorationItemModify({ rootGetters }, request) {
      return axios
        .post('DecorationItemModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          }
          if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('品項已存在')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DecorationItemDelete({ rootGetters }, request) {
      return axios
        .post('DecorationItemDelete', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          }
          if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DecorationItemList({ rootGetters }, request) {
      return axios
        .post('DecorationItemList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          }
          if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DecorationItemTypeList({ rootGetters }) {
      return axios
        .post('DecorationItemTypeList', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          }
          if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DecorationItemImport({ rootGetters }, request) {
      const formData = new FormData()

      formData.append('ExcelFile', request.ExcelFile)
      formData.append('IsOverride', request.IsOverride)

      return axios
        .post('DecorationItemImport', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('必填欄位為空')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('非法計價方式')
          } else if (response.data.Values.ReqInt === -4) {
            return Promise.reject('每支長度格式錯誤')
          } else if (response.data.Values.ReqInt === -5) {
            return Promise.reject('檔案內有重複輕裝修品項')
          } else if (response.data.Values.ReqInt === -6) {
            return Promise.reject('輕裝修品項重複')
          } else if (response.data.Values.ReqInt === -7) {
            return Promise.reject('非法類型')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DecorationItemExport({ rootGetters }, request) {
      return axios
        .post(
          'DecorationItemExport',
          {
            ...request
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DecorationMinimumPriceRead({ rootGetters }) {
      return axios
        .post('DecorationMinimumPriceRead', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          }
          if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DecorationMinimumPriceSet({ rootGetters }, request) {
      return axios
        .post('DecorationMinimumPriceSet', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('輕裝修最低價格設定成功')
          }
          if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DoorAccurateExtraPriceRead({ commit, rootGetters }) {
      return axios
        .post('DoorAccurateExtraPriceRead', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DoorAccurateExtraPriceSet({ commit, rootGetters }, request) {
      return axios
        .post('DoorAccurateExtraPriceSet', {
          ExtraPrice: request.ExtraPrice,
          MinimumSquareFoot: request.MinimumSquareFoot,
          Note: request.Note
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('門板特殊加價設定儲存成功')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MaterialsImport({ commit, rootGetters }, request) {
      const formData = new FormData()

      formData.append('MaterialExcelFile', request.MaterialExcelFile)
      return axios
        .post('MaterialsImport', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('匯入成功')
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('檔案異常，請檢查檔案格式是否正確')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('板材資料表欄位未填寫正確')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('門板資料表欄位未填寫正確')
          } else if (response.data.Values.ReqInt === -4) {
            return Promise.reject('板材資料表內有重複品項')
          } else if (response.data.Values.ReqInt === -5) {
            return Promise.reject('門板資料表內有重複品項')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MaterialsExport({ commit, rootGetters }, request) {
      return axios
        .post('MaterialsExport', { responseType: 'blob' })
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetProductionSchedule({ commit, rootGetters }, request) {
      return axios
        .post('CapacityReservation/GetProductionSchedule', request)
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetMaterialPreparationList({ commit, rootGetters }, request) {
      return axios
        .post('MaterialPreparation/GetMaterialPreparationList', request)
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetBoardHardware({ commit, rootGetters }, request) {
      return axios
        .post('MaterialPreparation/GetBoardHardware', request)
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetMaterialPreparationDetail({ commit, rootGetters }, request) {
      return axios
        .post('MaterialPreparation/GetMaterialPreparationDetail', request)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetOrderCapacityReservation({ commit, rootGetters }, request) {
      return axios
        .post('MaterialPreparation/GetOrderCapacityReservation', request)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetBoardHardwareInfoList({ commit, rootGetters }, request) {
      return axios
        .post('MaterialPreparation/GetBoardHardwareInfoList', request)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetMaterialPreparation({ commit, rootGetters }, request) {
      return axios
        .post('MaterialPreparation/SetMaterialPreparation', request)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetTransferProcurement({ commit, rootGetters }, request) {
      return axios
        .post('MaterialPreparation/SetTransferProcurement', request)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetPurchaseGoods({ commit, rootGetters }, request) {
      return axios
        .post('MaterialPreparation/SetPurchaseGoods', request)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ProductionSchedulingTasksList({ commit, rootGetters }, request) {
      return axios
        .post('MaterialPreparation/ProductionSchedulingTasksList', request)
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetMaterialsInfo({ commit, rootGetters }, request) {
      return axios
        .post('MaterialPreparation/GetMaterialsInfo', request)
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetBoardComplete({ commit, rootGetters }, request) {
      return axios
        .post('MaterialPreparation/SetBoardComplete', request)
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetHardWareComplete({ commit, rootGetters }, request) {
      return axios
        .post('MaterialPreparation/SetHardWareComplete', request)
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetReservationDate({ commit, rootGetters }, request) {
      return axios
        .post('CapacityReservation/SetReservationDate', request)
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetMesOrderDate({ commit, rootGetters }, request) {
      return axios
        .post('CapacityReservation/SetMesOrderDate', request)
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MaterialPreparationReviewList({ rootGetters }, request) {
      return axios
        .post('MaterialPreparation/MaterialPreparationReviewList', {
          ...request
        })
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetMaterialPassThrough({ rootGetters }, request) {
      return axios
        .post('MaterialPreparation/SetMaterialPassThrough', {
          ...request
        })
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetMaterialRefuse({ rootGetters }, request) {
      return axios
        .post('MaterialPreparation/SetMaterialRefuse', {
          ...request
        })
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MaterialReviewBoardHardDetails({ rootGetters }, request) {
      return axios
        .post('MaterialPreparation/MaterialReviewBoardHardDetails', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExportOrderMetalExcel({ rootGetters }, request) {
      return axios
        .post('MaterialPreparation/ExportOrderMetalExcel', { ...request }, { responseType: 'blob' })
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetBoardMetalsList({ rootGetters }, request) {
      return axios
        .post('MaterialPreparation/GetBoardMetalsList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetAssembyImageUrl({ rootGetters }, request) {
      return axiosInstance.post('GetAssembyImageUrl', {
        ...request
      })
      .then((response) => {
        if (response.data.Values.ReqInt === 0) {
          return Promise.resolve(response.data)
        } else if (response.data.Values.ReqInt === -1) {
          return Promise.reject(response.data.Values.Message)
        }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
    }
  }
}
